import "./Vision.css";

export default function Vision() {
  return (
    <section className="vision d-flex align-items-center" id="vision">
      <div className="container">
        <div className="row d-flex align-items-center justify-content-around">
          <div className="col-lg-5 order-2 order-lg-1 vision-image"></div>
          <div className="col-lg-4 order-1 order-lg-2">
            <div className="section-title">
              <h2>Vision</h2>
            </div>
            <p>
              Imagine a world where every Maasai girl is free to dream, learn,
              and lead. At the Adele Diamond Foundation, this vision is our
              driving force.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
