// import { Link } from "react-router-dom";
import "./Header.css";
import logo from "../../assets/img/logo.png";
import { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";

export default function Header() {
  const [mobileNavActive, setMobileNavActive] = useState(false);

  return (
    <header>
      <div className="logo-div">
        <img src={logo} alt="Adele Diamond logo" />
      </div>
      <nav
        className={`${mobileNavActive && "mobile-nav-active"}`}
        onClick={() => setMobileNavActive(false)}
      >
        <a href={"/#hero"} className="nav-option">
          Home
        </a>
        <a href={"/#about-us"} className="nav-option">
          About
        </a>
        <a href={"/#vision"} className="nav-option">
          Vision
        </a>
        <a href={"/#mission"} className="nav-option">
          Mission
        </a>
        <a href={"/#stories"} className="nav-option">
          Impact Stories
        </a>
        <a href={"/#cta"} className="nav-option">
          Call To Action
        </a>
      </nav>
      <div
        onClick={() => setMobileNavActive((current) => (current = !current))}
      >
        <MenuIcon className="menu-icon" />
      </div>
    </header>
  );
}
