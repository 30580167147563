import Hero from "../../sections/home/Hero";
import Aboutus from "../../sections/home/Aboutus";
import Cta from "../../sections/home/Cta";
import Mission from "../../sections/home/Mission";
import Stories from "../../sections/home/Stories";
import Vision from "../../sections/home/Vision";
import "./Home.css";

export default function Home() {
  return (
    <main className="home">
      <Hero />
      <Aboutus />
      <Vision />
      <Mission />
      <Stories />
      <Cta />
    </main>
  );
}
