import "./Mission.css";

export default function Mission() {
  return (
    <section className="mission d-flex align-items-center" id="mission">
      <div className="container">
        <div className="row d-flex align-items-center justify-content-around">
          <div className="col-lg-4">
            <div className="section-title">
              <h2>Mission</h2>
            </div>
            <p>
              Imagine a world where every Maasai girl is free to dream, learn,
              and lead. At the Adele Diamond Foundation, this mission is our
              driving force.
            </p>
          </div>
          <div className="col-lg-5 mission-image"></div>
        </div>
      </div>
    </section>
  );
}
