import "./Cta.css";

export default function Cta() {
  return (
    <section className="cta" id="cta">
      <div className="container d-flex flex-column align-items-center text-center justify-content-around">
        <div className="section-title">
          <h2>Call To Action</h2>
        </div>
        <p>
          Join us on our journey to transform lives and communities. Together,
          we can make a difference
        </p>
        <div className="section-btn">Get Involved</div>
      </div>
    </section>
  );
}
