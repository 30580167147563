import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";

import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import GetInvolved from "./pages/getInvolved/GetInvolved";
import Home from "./pages/home/Home";
import Work from "./pages/work/Work";
import Gallery from "./pages/gallery/Gallery";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="/work" element={<Work />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/get-involved" element={<GetInvolved />} />
          <Route path="/gallery" element={<Gallery />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
