import "./Hero.css";

export default function Hero() {
  return (
    <section id="hero" className="hero">
      <div className="container">
        <div className="content">
          <h1>Adele Diamond Foundation</h1>
          <p>
            Welcome to the Adele Diamond Foundation, where we believe in the
            transformative power of education. In the heart of the Maasai
            community in Kenya, we're working tirelessly to ensure every girl
            has the opportunity to shine.
          </p>
        </div>
      </div>
    </section>
  );
}
