import { useEffect, useRef, useState } from "react";
import "./Stories.css";

/* photo */
/* https://images.pexels.com/photos/7637400/pexels-photo-7637400.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load */
// https://images.pexels.com/photos/5716492/pexels-photo-5716492.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load
// https://images.pexels.com/photos/7637397/pexels-photo-7637397.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load
/* https://images.pexels.com/photos/17292030/pexels-photo-17292030/free-photo-of-person-wearing-colorful-shawl-standing-with-cattle.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2 */

// https://images.pexels.com/photos/18824545/pexels-photo-18824545/free-photo-of-group-of-women-from-an-african-village.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load
/* https://images.pexels.com/photos/12334810/pexels-photo-12334810.jpeg?auto=compress&cs=tinysrgb&w=800 */

export default function Stories() {
  const [isActive1, setIsActive1] = useState(true);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);

  const [isMobileSizeScreen, setIsMobileSizeScreen] = useState(false);

  const setActive = (setFunc) => {
    setIsActive1(false);
    setIsActive2(false);
    setIsActive3(false);
    setFunc(true);
  };

  useEffect(() => {
    if (window.innerWidth < 992) {
      setIsMobileSizeScreen(true);
    } else {
      setIsMobileSizeScreen(false);
    }
    window.addEventListener("scroll", () => {
      if (window.innerWidth < 992) {
        setIsMobileSizeScreen(true);
      } else {
        setIsMobileSizeScreen(false);
      }
    });
  }, []);
  const storyRef = useRef(null);

  const scrollToStory = () => {
    storyRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <section id="stories" className="stories">
      <div className="row">
        <div className="col-lg-5 content ">
          <div className="container">
            <div className="section-title">
              <h2>Impact Stories</h2>
            </div>

            <div>
              <p>
                Through the Adele Diamond Foundation, we witness the
                transformative power of education in the lives of Maasai girls.
                Each story is a testament to the profound impact of our
                sponsorship program, empowering bright young girls to defy
                societal norms and pursue their dreams.
              </p>
              <p>
                From Tumiso, who escaped the threat of early marriage to become
                an agent of change in her family, to Sarah, whose journey from
                adversity to opportunity inspires us all, these stories fuel our
                commitment to breaking down barriers and fostering a brighter
                future for Maasai girls and their communities.
              </p>
              {/* {isMobileSizeScreen && <div ref={storyRef}></div>} */}
            </div>
          </div>
        </div>
        <div className="col-lg-7 stories-div">
          {/* {!isMobileSizeScreen && <div ref={storyRef}></div>} */}

          {isActive1 && (
            <div className="story-text">
              <h3> Meet Naisula: </h3>
              <p>
                Once destined for early marriage, Naisula's life took a turn
                when she joined the Adele Diamond Foundation's sponsorship
                program. Through education, she discovered her passion for
                environmental conservation and now leads initiatives to protect
                Maasai lands. Naisula's journey from vulnerability to
                empowerment exemplifies the Foundation's mission to break
                barriers and cultivate leaders within the Maasai community.
              </p>
            </div>
          )}
          {isActive2 && (
            <div className="story-text">
              <h3>Introducing Kipkemboi:</h3>
              <p>
                Raised in poverty, Kipkemboi's prospects were limited until he
                encountered the Adele Diamond Foundation. With access to quality
                education and mentorship, he pursued his interest in technology
                and now spearheads innovative solutions for sustainable farming
                practices in Maasai villages. Kipkemboi's story underscores the
                Foundation's commitment to unlocking potential and fostering
                self-reliance among Maasai youth.
              </p>
            </div>
          )}
          {isActive3 && (
            <div className="story-text">
              <h3>Say Hello to Nasieku:</h3>
              <p>
                Nasieku defied societal expectations by prioritizing education
                over early marriage. Supported by the Adele Diamond Foundation,
                she emerged as a vocal advocate for girls' rights and
                environmental conservation. Nasieku's journey from adversity to
                advocacy exemplifies the Foundation's vision of a world where
                every Maasai girl can dream, learn, and lead without
                constraints.
              </p>
            </div>
          )}

          <div className="story-cards">
            <div className="row d-flex justify-content-evenly align-items-center">
              <div
                onClick={() => {
                  scrollToStory();
                  setActive(setIsActive1);
                }}
                className={`${
                  !isMobileSizeScreen && `col-${isActive1 ? "4" : "3"}`
                } story-card ${isActive1 && "active"} `}
              ></div>
              <div
                onClick={() => {
                  scrollToStory();
                  setActive(setIsActive2);
                }}
                className={`${
                  !isMobileSizeScreen && `col-${isActive2 ? "4" : "3"}`
                } story-card ${isActive2 && "active"} `}
              ></div>
              <div
                onClick={() => {
                  scrollToStory();
                  setActive(setIsActive3);
                }}
                className={`${
                  !isMobileSizeScreen && `col-${isActive3 ? "4" : "3"}`
                } story-card ${isActive3 && "active"} `}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
