import "./Aboutus.css";

export default function Aboutus() {
  return (
    <section className="about-us" id="about-us">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-lg-6">
            <div className="section-title">
              <h2> About Adele Diamond Foundation</h2>
            </div>
            <p>
              At the Adele Diamond Foundation, we believe in the transformative
              power of education. Nestled within the Maasai community of Kenya,
              our mission is clear: to empower every girl with the opportunity
              to shine. Through sponsorship programs and community outreach,
              we're rewriting the narrative for Maasai girls, breaking down
              barriers, and changing futures.
            </p>
            <p>
              Join us on this journey of transformation and be a part of
              creating a world where every girl is free to dream, learn, and
              lead
            </p>
          </div>
          <div className="col-lg-6 about-image"></div>
        </div>
      </div>
    </section>
  );
}
