import "./Footer.css";

export default function Footer() {
  return (
    <footer id="footer">
      <div className="container py-4">
        <div className="col-lg-4 col-md-6">
          <div className="footer-info">
            <h3>Adele Diamond Foundation</h3>
            <p>
              Kimbalat, Kitengela
              <br />
              P.O. Box 10236-00200,
              <br />
              Nairobi, Kenya
              <br />
              Tell: +254 11 3962565
              <br />
              Email: info@adelediamond.org
              <br />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="me-md-auto text-center text-md-start">
            <div className="copyright">
              &copy; Copyright{" "}
              <strong>
                <span>Adele Diamond Foundation</span>
              </strong>
              . All Rights Reserved
            </div>
            <div className="credits">
              Designed by
              <a href="https://www.linkedin.com/in/lumona-mulengwa-117909165/">
                Lumona Mulengwa
              </a>
            </div>
          </div>
          <div className="social-links text-center text-md-right pt-3 pt-md-0">
            <a href="#" className="twitter">
              <i className="bx bxl-twitter"></i>
            </a>
            <a href="#" className="facebook">
              <i className="bx bxl-facebook"></i>
            </a>
            <a href="#" className="instagram">
              <i className="bx bxl-instagram"></i>
            </a>
            <a href="#" className="linkedin">
              <i className="bx bxl-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
